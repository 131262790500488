// src/components/TeamMember.jsx
import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "../../styles/TeamMember.css";

const TeamMember = ({ name, title, photo, linkedin, email }) => {
  return (
    <div className="team-member">
      <div className="oval">
        <img src={photo} alt={name} />
      </div>
      <h3>{name}</h3>
      <p>{title}</p>
      <div className="links">
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href={`mailto:${email}`}>
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default TeamMember;
