// App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import ContactUs from "./components/ContactUs/ContactUs";
import Loader from "./components/Loader/Loader";
import AboutUs from "./components/AboutUs/AboutUs";
import Customers from "./components/Customers/Customers";
import CookieConsent from './components/CookieConsent/CookieConsent';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div>
        {loading && <Loader />}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/customers" element={<Customers />} />
          {/* Add routes for other pages */}
        </Routes>
        <CookieConsent />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
