// src/customers/Customers.jsx
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/Customers.css";
import snowflake from "../../assets/snowflake.png";
import salesforce from "../../assets/salesforce.webp";
import ebay from "../../assets/ebay.jpg";

const Customers = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <Container>
      <Row className={`mt-5 ${animate ? "fade-in" : ""}`}>
        <Col>
          <h2 className="section-header">
            We feel privileged to collaborate with extraordinary individuals
            reshaping their enterprises through meticulous data-driven
            strategies
          </h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h3 className="subsection-header">
            Collaborate with the industry's leading Go-to-Market teams.
          </h3>
          <div className="customer-logos-container">
            <img src={snowflake} alt="Snowflake" className="customer-logo" />
            <img src={salesforce} alt="Salesforce" className="customer-logo" />
            <img src={ebay} alt="eBay" className="customer-logo" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Customers;
