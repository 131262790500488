// src/components/ContactUs/ContactUs.js
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Container, Form, Button } from "react-bootstrap";
import "../../styles/ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.values(formData).some((value) => !value)) {
      toast.error("Please fill in all fields.");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("name", event.target.elements.formName.value);
    formDataToSend.append("email", event.target.elements.formEmail.value);
    formDataToSend.append("subject", event.target.elements.formSubject.value);
    formDataToSend.append("message", event.target.elements.formMessage.value);

    try {
      const response = await fetch("sendEmail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataToSend),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        event.target.reset();
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        toast.error("Error while submitting form! Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error("Error while submitting form! Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Container className="contact-us-container">
      <div className="contact-header">
        <h2>
          <i className="bi bi-envelope" style={{ color: "#007bff" }}></i>{" "}
          Contact Us
        </h2>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={!Object.values(formData).every(Boolean)}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default ContactUs;
