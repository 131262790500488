import React from "react";
import "../../styles/Home.css";
import Carousel from "./Carousel";
import testimonial1 from "../../assets/testimonial1.jpg";
import testimonial2 from "../../assets/testimonial2.jpg";

const TestimonialCarousel = () => {
  const testimonialsData = [
    {
      id: 1,
      photo: testimonial1,
      comment:
        "Great service! Novakonnect helped us boost our sales and reach new customers.",
      companyName: "ABC Corporation",
      position: "CEO",
    },
    {
      id: 2,
      photo: testimonial2,
      comment:
        "Novakonnect's data health optimization is unparalleled. Our contact lists have never been cleaner!",
      companyName: "XYZ Ltd.",
      position: "Marketing Director",
    },
    // Add more testimonials as needed
  ];

  return (
    <div className="carousel-container">
      <Carousel
        items={testimonialsData.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <img
              src={testimonial.photo}
              alt={`Client ${testimonial.id}`}
              className="testimonial-photo"
            />
            <p className="testimonial-comment">{testimonial.comment}</p>
            <p className="testimonial-details">
              {testimonial.companyName}, {testimonial.position}
            </p>
          </div>
        ))}
        showDots={true}
        autoplaySpeed={5000}
      />
    </div>
  );
};

export default TestimonialCarousel;
