// components/Home/Home.js
import React, { useState, useEffect } from "react";
import {
  faChartLine,
  faFileExcel,
  faBullhorn,
  faBalanceScale,
} from "@fortawesome/free-solid-svg-icons";
import "../../styles/Home.css";
import ServiceAccordion from "./ServiceAccordion";
import TestimonialCarousel from "./TestimonialCarousel";
import Carousel from "./Carousel";
import b2b1 from "../../assets/b2b1.jpg";
import b2b2 from "../../assets/b2b2.jpg";
import b2b3 from "../../assets/b2b3.jpg";

const Home = () => {
  const [showMoreContent, setShowMoreContent] = useState(false);
  const [cardDetails, setCardDetails] = useState([
    {
      id: 1,
      subject: "Precision + Scale",
      details:
        "Dive deep or go broad. Experience unmatched data precision, quality, and coverage on your terms.",
    },
    {
      id: 2,
      subject: "On Demand",
      details:
        "We're not just a generic database. We deliver custom-sourced data tailored to your specific business needs.",
    },
    {
      id: 3,
      subject: "Consolidation",
      details:
        "Upgrade or replace disjointed data solutions for better efficiency and ROI with Novakonnect.",
    },
  ]);

  const servicesData = [
    {
      id: 1,
      icon: faChartLine,
      title: "Sales Pipeline Advancement",
      description:
        "Optimize and enrich your sales strategy with our comprehensive database management, enhancing your pipeline efficiency.",
    },
    {
      id: 2,
      icon: faFileExcel,
      title: "Data Health Optimization",
      description:
        "Revitalize your contact lists with our detailed data cleaning and auditing, ensuring accuracy and reliability.",
    },
    {
      id: 3,
      icon: faBullhorn,
      title: "Lead Generation Mastery",
      description:
        "Strategically harnessing AI, we craft email and LinkedIn campaigns with precision to broaden your network, captivate potential leads, and optimize conversions.",
    },
    {
      id: 4,
      icon: faBalanceScale,
      title: "Competitive Insights",
      description:
        "Utilize AI to gain a strategic edge by delving deep into your competitors' data, extracting valuable customer insights, and staying ahead in the market.",
    },
  ];

  const [services, setServices] = useState(
    servicesData.map((service) => ({ ...service, isExpanded: false }))
  );

  const handleReadMore = (id) => {
    setServices((prevServices) =>
      prevServices.map((service) => ({
        ...service,
        isExpanded: service.id === id ? !service.isExpanded : false,
      }))
    );
  };

  const handleCardHover = (id) => {
    // Set the card's details to be displayed on hover
    const updatedCards = cardDetails.map((card) =>
      card.id === id ? { ...card, isHovered: true } : card
    );
    setCardDetails(updatedCards);
  };

  const handleCardLeave = () => {
    // Reset all cards on hover leave
    const resetCards = cardDetails.map((card) => ({
      ...card,
      isHovered: false,
    }));
    setCardDetails(resetCards);
  };

  const companyMottoData = [b2b1, b2b2, b2b3];

  useEffect(() => {
    // Simulate loading and reveal more content after a delay
    const delay = setTimeout(() => {
      setShowMoreContent(true);
    }, 4000); // Adjust the delay time as needed

    // Halt the animation after it completes
    const contentElement = document.querySelector(".motto-content");
    if (contentElement) {
      contentElement.addEventListener("animationend", () => {
        contentElement.classList.add("halt-animation");
      });
    }

    return () => {
      clearTimeout(delay);
    };
  }, []);

  return (
    <div className="home-container">
      <section className="company-motto-section-wrapper">
        <div className="company-motto-section">
          <div className="motto-content">
            <h2 className={showMoreContent ? "halt-animation" : ""}>
              {showMoreContent
                ? "Delivering Excellence and Innovation in Every Interaction. More content goes here..."
                : " "}
            </h2>
          </div>
          <div className="carousel-container">
            <Carousel
              items={companyMottoData.map((photo, index) => (
                <div key={index}>
                  <img
                    src={photo}
                    alt={`Company Motto ${index + 1}`}
                    className="motto-photo"
                  />
                </div>
              ))}
              showDots={false}
              autoplaySpeed={5000}
            />
          </div>
        </div>
      </section>

      <div className="services-section scroll-fade-in visible">
        <div className="home-section-header">Services</div>
        <ServiceAccordion services={services} handleReadMore={handleReadMore} />
      </div>

      <div className="why-choose-us-section">
        <div className="home-section-header">Why Choose Us?</div>
        <section className="p-5">
          <div className="card-deck">
            {cardDetails.map((card) => (
              <div
                key={card.id}
                className={`card border-primary ${
                  card.isHovered ? "is-hovered" : ""
                }`}
                onMouseEnter={() => handleCardHover(card.id)}
                onMouseLeave={handleCardLeave}
              >
                <div className="card-body">
                  <h5
                    className={`card-title text-primary ${
                      card.isHovered ? "hidden" : ""
                    }`}
                  >
                    {card.subject}
                  </h5>
                  <p className={`card-text ${card.isHovered ? "" : "hidden"}`}>
                    {card.details}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div className="testimonial-section">
        <div className="home-section-header">Testimonials</div>
        <TestimonialCarousel />
      </div>
    </div>
  );
};

export default Home;
