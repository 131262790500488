import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../../styles/Home.css";

const ServiceAccordion = ({ services, handleReadMore }) => {
  const [expandedId, setExpandedId] = useState(null);
  const accordionRef = useRef();

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setExpandedId(null);
    }
  };

  const handleOnClick = (id) => {
    handleReadMore((prevId) => (prevId === id ? null : id));
    setExpandedId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="services-accordion" ref={accordionRef}>
      {services.map((service) => (
        <div
          key={service.id}
          className={`service-accordion-item ${
            expandedId === service.id ? "expanded" : ""
          }`}
        >
          <div
            className="service-header"
            onClick={() => handleOnClick(service.id)}
          >
            <FontAwesomeIcon icon={service.icon} className="service-icon" />
            <div className="service-details">
              <div className="service-subject">{service.title}</div>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className="right-arrow" />
          </div>
          {expandedId === service.id && (
            <div className="service-details-expanded">
              <div className="service-body">{service.description}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ServiceAccordion;
