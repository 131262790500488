// src/components/cookie/CookieConsent.jsx
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import "../../styles/CookieConsent.css";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [showBanner, setShowBanner] = useState(!cookies.cookieConsent);

  useEffect(() => {
    setShowBanner(!cookies.cookieConsent);
  }, [cookies.cookieConsent]);

  const handleAccept = () => {
    setCookie("cookieConsent", true, { path: "/", maxAge: 365 * 24 * 60 * 60 });
  };

  return (
    showBanner && (
      <div className="cookie-consent">
        <p>
          This website uses cookies. By continuing to use this site, you accept
          our use of cookies.
        </p>
        <button onClick={handleAccept}>Accept</button>
      </div>
    )
  );
};

export default CookieConsent;
