// src/components/AboutUs.js
import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/AboutUs.css";
import mission from "../../assets/our-mission.webp";
import TeamMember from "./TeamMember";
import { useInView } from "react-intersection-observer";
import amitp from "../../assets/amit-pandey.jpg";
import dharmesh from "../../assets/dharmesh.jpg";

const AboutUs = () => {
  const animatedSectionRef = useRef(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [animated, setAnimated] = useState(false);

  if (inView && !animated) {
    setAnimated(true);
  }

  useEffect(() => {
    const animatedSection = animatedSectionRef.current;

    const handleScroll = () => {
      if (isInViewport(animatedSection)) {
        animatedSection.classList.add("active");
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  const values = [
    "Think, don't follow",
    "Team, Team, Team",
    "Communication",
    "If there's a problem, own it",
  ];

  const teamMembers = [
    {
      name: "Amit Pandey",
      title: "Director",
      photo: amitp,
      linkedin: "https://in.linkedin.com/in/amit-pandey-578320182",
      email: "amit@novakonnect.com",
    },
    {
      name: "Dharmesh Gupta",
      title: "Director",
      photo: dharmesh,
      linkedin: "https://in.linkedin.com/in/xxx",
      email: "dharmesh@novakonnect.com",
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <div ref={animatedSectionRef} className="animated-section">
            <h1 className="section-title">
              It's not centered on us, but on empowering your Go-to-Market
              squad.
            </h1>
            <p className="animated-description">
              At Nova Konnect, our mission is to assist Go-to-Market teams
              driven by data, such as yours, in discovering their perfect
              customers. We are firm believers that harnessing the power of
              accurate data can enable organizations to accomplish remarkable
              feats. Ultimately, our focus is on aiding you in pinpointing your
              next customers through precise data.
            </p>
          </div>
        </Col>
      </Row>
      <div className="mission-section">
        <div className="mission-content animated">
          <h2>Our Mission</h2>
          <p className="mission-quote">
            "Our passion is using data to help people find their ideal
            customers" - Dharmesh Gupta
          </p>
        </div>
        <div className="mission-image animated">
          <img src={mission} alt="Mission" />
        </div>
      </div>
      <div className="values-section">
        <h2 className="values-header animated">Our Values</h2>
        <div className="values-cards">
          {values.map((value, index) => (
            <div key={index} className="value-card animated">
              <p>{value}</p>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`our-team-section ${animated ? "animated in-view" : ""}`}
        ref={ref}
      >
        <h2 className="our-team-header">Our Team</h2>
        <div className="team-members">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
