// src/components/Carousel.js
import React from "react";
import Slider from "react-slick";
import "../../styles/Home.css"; // Create this CSS file for custom styling

const Carousel = ({ items, showDots, autoplaySpeed }) => {
  const slickSettings = {
    dots: showDots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: autoplaySpeed, // Set the autoplay interval in milliseconds
  };

  const slides = items.map((item, index) => (
    <div key={index} className="testimonial-slide">
      {item}
    </div>
  ));

  return <Slider {...slickSettings}>{slides}</Slider>;
};

export default Carousel;
