// components/Loader.js
import React from "react";
import "../../styles/Loader.css";
import { DotLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="spinner-container">
          <div className="sweet-loading">
            <DotLoader color="#3498db" loading={true} size={60} />
            <div className="loader-text">Loading Nova Konnect...</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
