// components/Header.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/nova-konnect-logo.png";
import "../../styles/Header.css";

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(null);

  const updateActiveMenu = (path) => {
    setActiveMenu(path);
  };

  useEffect(() => {
    if (activeMenu === null) {
      updateActiveMenu("/");
    } else {
      updateActiveMenu(window.location.pathname);
    }
  }, [activeMenu]);

  return (
    <header className="bg-dark text-white">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/" className="logo" onClick={() => setActiveMenu("/")}>
            <img src={logo} alt="Logo" />
          </Link>
          <div className="menu">
            <Link
              to="/"
              className={`menu-link ${activeMenu === "/" ? "active" : ""}`}
              onClick={() => setActiveMenu("/")}
            >
              Home
            </Link>
            <Link
              to="/services"
              className={`menu-link ${
                activeMenu === "/services" ? "active" : ""
              }`}
              onClick={() => setActiveMenu("/services")}
            >
              Services
            </Link>
            <Link
              to="/customers"
              className={`menu-link ${
                activeMenu === "/customers" ? "active" : ""
              }`}
              onClick={() => setActiveMenu("/customers")}
            >
              Customers
            </Link>
            <Link
              to="/about-us"
              className={`menu-link ${
                activeMenu === "/about-us" ? "active" : ""
              }`}
              onClick={() => setActiveMenu("/about-us")}
            >
              About Us
            </Link>
            <Link
              to="/contact-us"
              className={`menu-link ${
                activeMenu === "/contact-us" ? "active" : ""
              }`}
              onClick={() => setActiveMenu("/contact-us")}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
