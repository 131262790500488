// src/components/Footer.jsx
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/Footer.css";
import logo from "../../assets/nova-konnect-logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={3}>
            <Link to="/">
              <img src={logo} alt="Company Logo" className="footer-logo" />
            </Link>
            {/* <div className="gdpr-logo">GDPR Ready Logo</div> */}
          </Col>

          <Col md={3}>
            <h4>Quick Links</h4>
            <ul className="footer-links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/service">Service</Link>
              </li>
              <li>
                <Link to="/customers">Customers</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </Col>

          <Col md={3}>
            <h4>Services</h4>
            <ul className="footer-services">
              <li>Sales Pipeline Advancement</li>
              <li>Data Health Optimization</li>
              <li>Lead Generation Mastery</li>
              <li>Competitive Insights</li>
            </ul>
          </Col>

          <Col md={3}>
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a href="facebook-link" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="instagram-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="linkedin-link" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <p className="text-center copyright">
              &copy; 2024 Nova Konnect. All rights reserved.
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <p className="text-center">
              <FontAwesomeIcon icon={faSignature} /> Designed by{" "}
              <a
                href="https://rohinsingh.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-designed-by"
              >
                Rohin Singh
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
